/* @import '~antd/dist/antd.less'; */
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
  height: 0;
}
.clearfix {
  clear:both
}
.right{
    float: right;
}

.carousel-wrap{
  padding: 0 10px
}
.table-brand-items{
  float: left;
  margin-right: 5px;
}
.small-company-info .ant-form-item{
  margin-bottom: 6px;
}
.span-brand-wrap span{
  margin-right: 5px
}
.mgr-sidebar{
    margin-bottom: -10000px;
    padding-bottom: 10000px;
    background-color: #22335e;
    overflow: hidden;
    
}

/*
** 系统设置>菜单管理
*/
.admin-menu-cfg .admin-menu-cfg-menus{
    min-height: 300px;
    height: 300px;
    max-height: 550px;
    overflow:auto;
}
.admin-menu-cfg .admin-menu-cfg-tree{
    min-height: 300px;
    height: 300px;
    max-height: 550px;
    overflow:auto;
}
